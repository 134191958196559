<template>
  <div class="sidebar" :class="{ 'sidebar--closed': !isSideBarOpen }">
    <div class="sidebar-content">
      <router-link
        tag="img"
        class="sidebar-content__logo cursor-pointer"
        :src="
          isBrand(CUSTOM_APPEARANCE.MBH)
            ? require('@/assets/images/mbh-logo.svg')
            : require('@/assets/images/instacash-logo-white.svg')
        "
        to="/"
      />

      <menu-content></menu-content>

      <div
        @click="isSideBarOpen = !isSideBarOpen"
        class="sidebar-toggle"
        :class="{ 'sidebar-toggle--closed': !isSideBarOpen }"
      >
        <b-icon icon="chevron-left" type="is-white" size="is-small"> </b-icon>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { CUSTOM_APPEARANCE, UI_CONFIGS } from "@/utils/const";
import { isBrand } from "@/utils/util";

export default {
  name: "SideBar",
  methods: { isBrand },
  data() {
    return {
      isSideBarOpen: true,
    };
  },
  components: {
    MenuContent: () => import("@/components/MenuContent.vue"),
  },
  computed: {
    CUSTOM_APPEARANCE() {
      return CUSTOM_APPEARANCE;
    },
    UI_CONFIGS() {
      return UI_CONFIGS;
    },
    ...mapGetters({
      autoloadConfigValue: "config/getAutoloadConfigValue",
    }),
  },
};
</script>

<style scoped lang="scss">
@media only screen and (max-width: 768px) {
  .sidebar {
    display: none;
  }
}
</style>
